import React from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";
import moment from "moment";
function SessionDetailsFinal({ caseDetails, selectedSession }) {
    // console.log(caseDetails, "caseDetailscaseDetailscaseDetailscaseDetails");
    return (
        <>
            <Container>
                <Heading>Case Details</Heading>
                <PatientNameDiv>
                    <PatientName>
                        <Text>Case ID</Text>
                    </PatientName>
                    <span>:</span>
                    <Name>
                        {" "}
                        <NameText>{caseDetails.session_id}</NameText>
                    </Name>
                </PatientNameDiv>
                <PatientNameDiv>
                    <PatientName>
                        <Text>No of Sessions</Text>
                    </PatientName>
                    <span>:</span>
                    <Name>
                        {" "}
                        <NameText>{caseDetails?.total_sessions}</NameText>
                    </Name>
                </PatientNameDiv>
                <PatientDetailsDiv>
                    <PatientNameDiv>
                        <PatientName>
                            <Text>Session ID</Text>
                        </PatientName>
                        <span>:</span>
                        <Name>
                            {" "}
                            <NameText>{caseDetails.session_record_id}</NameText>
                        </Name>
                    </PatientNameDiv>
                    <PatientNameDiv>
                        <PatientName>
                            {" "}
                            <Text>Expert Name</Text>
                        </PatientName>
                        <span>:</span>
                        <Name>
                            {" "}
                            <NameText>{caseDetails?.expert_name}</NameText>
                        </Name>
                    </PatientNameDiv>
                    <PatientNameDiv>
                        <PatientName>
                            {" "}
                            <Text>Date of session</Text>
                        </PatientName>
                        <span>:</span>
                        <Name>
                            {" "}
                            <NameText>
                                {moment(selectedSession?.case_date).format(
                                    "DD MMMM YYYY"
                                )}
                            </NameText>
                        </Name>
                    </PatientNameDiv>
                    <PatientNameDiv>
                        <PatientName>
                            {" "}
                            <Text>Session Type</Text>
                        </PatientName>
                        <span>:</span>
                        <Name>
                            {" "}
                            <NameText>
                                {caseDetails?.meet_mode === "Video"
                                    ? "Online video session"
                                    : "Voice call only"}
                            </NameText>
                        </Name>
                    </PatientNameDiv>
                </PatientDetailsDiv>
                {/* <PatientDetailsDiv>
                    <PatientNameDiv>
                        <PatientName>
                            <Text>Session ID</Text>
                        </PatientName>
                        <span>:</span>
                        <Name>
                            {" "}
                            <NameText>#SID00001</NameText>
                        </Name>
                    </PatientNameDiv>
                    <PatientNameDiv>
                        <PatientName>
                            {" "}
                            <Text>Expert Name</Text>
                        </PatientName>
                        <span>:</span>
                        <Name>
                            {" "}
                            <NameText> Sophia Julie</NameText>
                        </Name>
                    </PatientNameDiv>
                    <PatientNameDiv>
                        <PatientName>
                            {" "}
                            <Text>Date of session</Text>
                        </PatientName>
                        <span>:</span>
                        <Name>
                            {" "}
                            <NameText>5 January, 2023</NameText>
                        </Name>
                    </PatientNameDiv>
                    <PatientNameDiv>
                        <PatientName>
                            {" "}
                            <Text>Session Type</Text>
                        </PatientName>
                        <span>:</span>
                        <Name>
                            {" "}
                            <NameText>Online video session</NameText>
                        </Name>
                    </PatientNameDiv>
                </PatientDetailsDiv>
                <PatientDetailsDiv>
                    <PatientNameDiv>
                        <PatientName>
                            <Text>Session ID</Text>
                        </PatientName>
                        <span>:</span>
                        <Name>
                            {" "}
                            <NameText>#SID00001</NameText>
                        </Name>
                    </PatientNameDiv>
                    <PatientNameDiv>
                        <PatientName>
                            {" "}
                            <Text>Expert Name</Text>
                        </PatientName>
                        <span>:</span>
                        <Name>
                            {" "}
                            <NameText> Sophia Julie</NameText>
                        </Name>
                    </PatientNameDiv>
                    <PatientNameDiv>
                        <PatientName>
                            {" "}
                            <Text>Date of session</Text>
                        </PatientName>
                        <span>:</span>
                        <Name>
                            {" "}
                            <NameText>5 January, 2023</NameText>
                        </Name>
                    </PatientNameDiv>
                    <PatientNameDiv>
                        <PatientName>
                            {" "}
                            <Text>Session Type</Text>
                        </PatientName>
                        <span>:</span>
                        <Name>
                            {" "}
                            <NameText>Online video session</NameText>
                        </Name>
                    </PatientNameDiv>
                </PatientDetailsDiv> */}
            </Container>
        </>
    );
}

export default SessionDetailsFinal;
const Container = styled.div`
    border: 1px solid ${THEME_COLORS.light_300};
    padding: 10px;
    border-radius: 8px;
    box-shadow: ${THEME_COLORS.elevation_expert_e1};
    /* margin-bottom: 20px; */
`;
const Heading = styled.h5`
    font-size: 14px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 10px;
`;
const PatientNameDiv = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    :last-child {
        margin-bottom: 0;
    }
`;
const PatientName = styled.div`
    width: 50%;
`;
const Name = styled.h5`
    width: 40%;
`;
const PatientDetailsDiv = styled.ul`
    background-color: ${THEME_COLORS.light_200};
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 10px;
`;
const Text = styled.h5`
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
`;
const NameText = styled.h5`
    font-size: 14px;
    color: ${THEME_COLORS.text_title};
`;
